<script lang="ts">
  import { newsletterSubscriptionStore as store } from "../stores";
  interface Props {
    children?: import("svelte").Snippet;
  }

  let { children }: Props = $props();
</script>

<div
  role="button"
  style={`
    cursor: pointer;
  `}
  tabindex="0"
  onkeydown={(e) => {
    if (e.key === "Enter") {
      $store = { modalOpen: true, submitted: false };
    }
  }}
  onclick={() => {
    $store = { modalOpen: true, submitted: false };
  }}
>
  {@render children?.()}
</div>
